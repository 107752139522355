<script setup>
import { v4 as uuidv4 } from 'uuid';
import { useCookie } from 'nuxt/app'
import { hashObject } from '~/composables/hashUtility';

const { getRecShared } = useLeadGenRecData();
const { data: recSharedData } = await getRecShared();
const gtm = useGtm();

// Define props
const props = defineProps({
  data: Object,
});
// Data for header
const sharedHeaderImg =
  recSharedData.value && recSharedData.value.data.attributes.header 
    ? recSharedData.value.data.attributes.header.image.data.attributes.url : '';
const sharedHeaderTitle = 
  recSharedData.value && recSharedData.value.data.attributes.header
    ? recSharedData.value.data.attributes.header.image.data.attributes.caption : '';
const sharedHeaderText = 
  recSharedData.value && recSharedData.value.data.attributes.header ?
    recSharedData.value.data.attributes.header.image.data.attributes.alternativeText : '';
const sharedHeaderLink = 
  recSharedData.value && recSharedData.value.data.attributes.header ?
    recSharedData.value.data.attributes.header.link : '';
const headerImage =
  props.data.customHeader && props.data.customHeader.image.url
    ? props.data.customHeader.image.url
    : sharedHeaderImg ;
const headerTitle = 
  props.data.customHeader && props.data.customHeader.image.caption
    ? props.data.customHeader.image.caption
    : sharedHeaderTitle;
const headerText = 
  props.data.customHeader && props.data.customHeader.image.alternativeText
    ? props.data.customHeader.image.alternativeText
    : sharedHeaderText;
const headerLink = props.data.customHeader
    ? (props.data.customHeader.link !== '' ? props.data.customHeader.link : '')
    : sharedHeaderLink;

// Define reactive variables
const currentStep = ref(0);
const studentName = ref("");
const childNestedStep = ref({});
const stepIsValid = ref(false);
const logoWidth = ref('auto');
const steps = ref(props.data.steps.data);
const config = useRuntimeConfig()
const cookiesArr = ['utm_source', 'utm_medium', 'utm_term', 'utm_campaign', 'utm_content', 'utmSiteVisitDate', 'User_Agent', 'DestinationUrl'];
const loading = ref(false);
let isGoingBack = ref(false);

//TODO check what name is on grid filters and create a link with these params for course grid
// Default data for course grid
const gridData = ref({
  interests: ['all'], // genre CourseRecGenre
  programs: ['all'], // courseRecPrograms
  age: 'all', // CourseRecAges
  format: ['all'], // courseRecLearningStyle
  location: '' //courseRecSearch
});
const createCourseUrl = () => {
  let gridUrl = window.location.origin + '/courses?'
  for (let key in gridData.value) {
    if (Array.isArray(gridData.value[key])) {
      const value = gridData.value[key].join(',')
      gridUrl += key + '=' + value + '&'
    } else {
      gridUrl += key + '=' + gridData.value[key] + '&'
    }
  }
  return gridUrl
};
const pulseData = ref({
  leadTypeID : 67007,
  emailAddress: '',
  students: [{
    studentFirstName: '',
    studentBirthYear: 0
  }],
  studentGenreTypeIDs: [295000,295001,295002,295003,295006,295008],
  learningStyleTypeIDs: [405000,405001,405002,405003],
  courseRecUrl: '',
  postalCode: '',
  firstName: ''
});

 // Initialize componentsValid for each step dynamically
onMounted(() => {
  steps.value.forEach((step) => {
    componentsValid.value[step.id] = {}; // Initialize validation for each step

    step.attributes.blocks.forEach((block) => {
      componentsValid.value[step.id][block.component] = false; // Initially set all components as invalid
    });
  });
});

// Function to get the title of the next button
const getNextButtonTitle = () => {
  return steps.value[currentStep.value].attributes.nextBtnTitle;
};

// Function to get the title of the skip button
const getSkipButtonTitle = () => {
  return steps.value[currentStep.value].attributes.skipBtnTitle;
};

function triggerNext() {
  gtm.trackEvent({
    event: recSharedData.value.data.attributes.titleGtmPrimary + '_' + (currentStep.value + 1) + '_' + steps.value[currentStep.value].attributes.title ,
  })
}
function triggerSkip() {
  gtm.trackEvent({
    event: recSharedData.value.data.attributes.titleGtmSecondary + '_' + (currentStep.value + 1) + '_' + steps.value[currentStep.value].attributes.title ,
  })
}
function triggerBack() {
  gtm.trackEvent({
    event: recSharedData.value.data.attributes.titleGtmBack + '_' + (currentStep.value + 1) + '_' + steps.value[currentStep.value].attributes.title ,
  })
}

// Function to go to the next step
const nextStep = () => {
  const isValid = validateEmailComponent('#course-rec-email');
  if (!isValid) return;
  triggerNext();
  if (childNestedStep.value) {
    handleNestedStep(childNestedStep.value)
  }
  // Proceed to the next step if not at the last step
    if (currentStep.value < steps.value.length - 1 ) {
      currentStep.value++;    
    }
    updateCustomizedParams(); 
};

const stepToGridDataMap = computed(() => {
  return steps.value.reduce((map, step) => {
    if (step.id) {
      map[step.id] = step.attributes.tagStep; // Map step.id to the corresponding field
    }
    return map;
  }, {});
});
// Function to skip to the next step
const skipStep = () => {
  const currentIndex = currentStep.value;
  triggerSkip();
  const currentStepId = steps.value[currentIndex].id; // Assuming each step has a unique `id`
  // Dynamically reset gridData fields based on the current step's ID
  const gridDataField = stepToGridDataMap.value[currentStepId];
  if (gridDataField) {
    gridData.value[gridDataField] = ['all'];
  }

  // If user is going back and skipping, remove the nested step
  if (isGoingBack.value && steps.value[currentIndex + 1] && steps.value[currentIndex + 1].isChild) {
    let updatedSteps = [...steps.value];
    updatedSteps.splice(currentIndex + 1, 1);
    steps.value = updatedSteps;
  }
  // Reset the navigation direction tracker
  isGoingBack.value = false;

  if (currentStep.value < steps.value.length - 1) {
    if (!studentName.value) {
      handleStudentName(studentName.value);
    }
   currentStep.value++;   
  }
};
// Function to handle back arrow
const goBack = () => {
  triggerBack();
  if (currentStep.value > 0) {
    isGoingBack.value = true;
    currentStep.value--;
  }  
};
const lastCurrentStep = ref(0);
// Calculate progress width based on current step
const progressWidth = computed(() => {
  if (steps.value.length == currentStep.value + 1 || !steps.value[currentStep.value + 1].isChild) {
    lastCurrentStep.value = currentStep.value;
    return ((currentStep.value + 1) / steps.value.length) * 100 + "%";
  } else {
    return ((lastCurrentStep.value + 1) / (steps.value.length - ((currentStep.value + 1)  - lastCurrentStep.value))) * 100 + "%";
  }
});

// Function to handle a new step emitted by child components
const handleNestedStep = (step) => {
  const currentIndex = currentStep.value;
  if (step[currentIndex]) {
    // Clone the steps array to ensure reactivity
    let updatedSteps = [...steps.value];

    // Check if the next step is a child step and remove it
    if (updatedSteps[currentIndex + 1] && updatedSteps[currentIndex + 1].isChild && step) {
      updatedSteps.splice(currentIndex + 1, 1);
    }

    const newId = uuidv4();
    const newStep = {
      id: newId,
      attributes: step[currentIndex],
      isChild: true,
    };

    // Insert the new child step after the current step
    updatedSteps.splice(currentIndex + 1, 0, newStep);

    // Assign the updated steps back to the reactive variable
    steps.value = updatedSteps;  
    // Clear the pending nested step for this step after adding it
    delete childNestedStep.value[currentIndex]; 
    // Reset the navigation direction tracker
    isGoingBack.value = false;
  }
  else {
    // Check if a nested step was previously added and remove it
    if (steps.value[currentIndex + 1] && steps.value[currentIndex + 1].isChild) {
      let updatedSteps = [...steps.value];
      updatedSteps.splice(currentIndex + 1, 1);
      steps.value = updatedSteps;
    }
  }
};

const customizedSteps = [];

const updateCustomizedParams = () => {
  // Set the student name if it is already entered
  const regex = /&lt;&lt;.*?&gt;&gt;/g;

  // Iterate over each step and replace the placeholder with the student's name
  steps.value.forEach((step) => {
    step.attributes.blocks.forEach((block) => {
      if (block.component === "dynamic-blocks.generic-content") {
        // Store the original content if not already stored
        if (!block.originalContent) {
          block.originalContent = block.content;
        }
        const match = block.content.match(regex);
        if (match) {
          customizedSteps.push(step.id);
        }

        // Replace the placeholder with the student's name if a name is entered
        if (studentName.value) {
          block.content = block.originalContent.replaceAll(regex, studentName.value);
        } else if (customizedSteps.includes(step.id)) {
          // If no name is entered, revert to the original content without the name placeholder
          block.content = step.attributes.backupQuestion;
        }
      }
    });
  });
  
}
const componentsValid = ref({});

// Function to customize student name
const handleStudentName = (name) => {
  studentName.value = name;
};
const pendingNestedStep = (step) => {
  if (step) {
    childNestedStep.value[currentStep.value] = step;
  } else {
    delete childNestedStep.value[currentStep.value];
  }
}

// Function to handle validation
const handleValidateStep = (data) => {
  const value = data.value; // Ensure you pass the correct value to the validation function
  const currentStepVal = steps.value[currentStep.value]
  // Validate the value for the component
  const isValid =
    value !== undefined &&
    value !== null &&
    !(Array.isArray(value) && value.length === 0) &&
    value !== "";
  // Ensure that currentStep is defined and has an id
  if (currentStepVal && currentStepVal.id) {
    // Initialize the validation state for the current step if not already
    if (!componentsValid.value[currentStepVal.id]) {
      componentsValid.value[currentStepVal.id] = {};
    }
    componentsValid.value[currentStepVal.id][data.component] = isValid;
  }
};

const validateStep = computed(() => {
  const nextStepAttributes = steps.value[currentStep.value]?.attributes;
  const isWYSIWYGOnly = nextStepAttributes?.blocks.length === 1 && nextStepAttributes.blocks[0].component === 'dynamic-blocks.generic-content';
  // Check if all non-generic components in the current step are valid
  const allComponentsValid = Object.entries(componentsValid.value[steps.value[currentStep.value]?.id] || {})
    .filter(([componentName]) =>
      nextStepAttributes?.blocks?.some(
        (block) => block.component === componentName && block.component !== 'dynamic-blocks.generic-content'
      )
    )
    .every(([, valid]) => valid === true);

  // Return true if all components in the current step are valid
  return !getSkipButtonTitle()
    ? isWYSIWYGOnly || allComponentsValid
    : allComponentsValid;
});

const isStepValid = computed(() => {
  return validateStep.value; // `validateStep` holds the validation logic for all components
});

  const validateEmailComponent = (componentId) => {
  const courseRecEmailComponent = document.querySelector(componentId);

  if (
    courseRecEmailComponent &&
    window.getComputedStyle(courseRecEmailComponent.parentElement.parentElement.parentElement).display !== 'none'
  ) {
    const placeholderLabel = courseRecEmailComponent.querySelector('.placeholder-label');
    const errorLabel = courseRecEmailComponent.querySelector('.error-label');
    const emailInput = courseRecEmailComponent.querySelector('input[type="email"]');

    const isValid = emailInput.checkValidity();

    if (!isValid) {
      emailInput.classList.add('is-invalid');
      placeholderLabel.classList.replace('d-block', 'd-none');
      errorLabel.classList.replace('d-none', 'd-block');
      return false;
    } else {
      emailInput.classList.remove('is-invalid');
      placeholderLabel.classList.replace('d-none', 'd-block');
      errorLabel.classList.replace('d-block', 'd-none');
      return true;
    }
  }
  return true;
};

const handleSubmit = async () => {
  const isValid = validateEmailComponent('#course-rec-email');
  if (!isValid) return;

  pulseData.value.sourcePageUrl = window.location.href;
  pulseData.value.courseRecUrl = createCourseUrl();

  // Update form object with cookies parameters
  for (let cookieName in cookiesArr) {
    let cookie = useCookie(cookiesArr[cookieName]);
    if (cookie && cookie.value !== '') {
      pulseData.value[cookiesArr[cookieName]] = cookie.value;
    }
  }

  const isObject = (objValue) => objValue && typeof objValue === 'object' && objValue.constructor === Object;

  if (props.data.tagsArray && props.data.tagsArray.length > 0) {
    const tags = [];
    if (isObject(props.data.tagsArray)) {
      for (let i = 0; i < props.data.tagsArray.length; i++) {
        tags.push(props.data.tagsArray[i].tag);
      }
    } else {
      for (let i = 0; i < props.data.tagsArray.length; i++) {
        if (isObject(props.data.tagsArray[i])) {
          tags.push(props.data.tagsArray[i].tag);
        } else {
          tags.push(props.data.tagsArray[i]);
        }
      }
    }
    pulseData.value['tagsArray'] = tags;
  }

  const formJSON = JSON.stringify(pulseData.value);
  const hashedData = hashObject(pulseData.value); 

  dataLayer.push({
    'event': 'formSubmitted',
    'leadsUserData': hashedData
  });

  if (pulseData.value.emailAddress !== '') {
    loading.value = true; // Set loading to true when the request starts

    try {
      const nuxtApp = useNuxtApp(); // Ensure this is called correctly
      const apiResponse = await nuxtApp.postData(config.public.pulseAPILeadGenEndpoint, formJSON);

      if (apiResponse.message === 'The request is invalid.' || apiResponse.message === 'An error has occurred.') {
        errMsg.value = apiResponse.message;
        loading.value = false; // Ensure loading is set to false if there's an error
      } else {
        // Redirect only after the API call is successful
        location.href = pulseData.value.courseRecUrl;
      }
    } catch (error) {
      console.error('Error while submitting form:', error);
      // Handle any errors that occurred during the request
      loading.value = false; // Ensure loading is set to false if there's an error
    }
  } else {
    // Redirect if no email address is provided
    location.href = pulseData.value.courseRecUrl;
  }
};

</script>

<template>
  <div class="lead-gen-rec">
    <div class="lead-gen-rec-header">
      <!-- Back arrow -->
      <div class="back-arrow" v-if="currentStep > 0" @click="goBack">
        <i class="bi bi-chevron-left"></i>
      </div>

      <!-- Logo container -->
      <div class="logo-container" :class="{ 'with-arrow': currentStep > 0 }" :style="{ width: logoWidth }">
        <!-- Logo -->
        <div class="logo">
          <!-- Render the logo dynamically from CMS -->
          <a :href="headerLink" class="logo-link" v-if="headerLink !== ''">
            <img :src="headerImage" :title="headerTitle" :alt="headerText" />
          </a>
          <img :src="headerImage" :title="headerTitle" :alt="headerText" v-else />
        </div>
      </div>
    </div>

    <!-- Progress bar -->
    <div class="progress-bar">
      <div class="progress" :style="{ width: progressWidth }"></div>
    </div>

    <div class="content">
      <div
        v-for="(step, index) in steps"
        :key="step.id"
        v-show="currentStep === index"
        class="step-container"
      >
        <!-- LayoutDynamicComponents component to render dynamic content -->
        <LayoutDynamicComponents
          :sections="step.attributes.blocks"
          @nestedStep="pendingNestedStep"
          @studentName="handleStudentName"
          @validateStep="handleValidateStep"
          :pulseData="pulseData"
          :gridData="gridData"
        />
      </div>
    </div>

    <!-- Next button -->
    <div class="lead-gen-rec-footer">
      <button
        id="next-cta"
        class="btn btn-primary next-step-cta"
        :class="{ disabled: !isStepValid || loading }"
        v-if="currentStep < steps.length - 1 && getNextButtonTitle() !== null"
        @click="nextStep"
        :disabled="!isStepValid || loading"
      >
        {{ getNextButtonTitle() }}
      </button>
      <button
        id="skip-cta"
        class="btn btn-secondary next-step-cta"
        v-if="currentStep < steps.length - 1 && getSkipButtonTitle() !== null"
        @click="skipStep"
      >
        {{ getSkipButtonTitle() }}
      </button>
      <button
        class="btn btn-primary next-step-cta"
        v-if="currentStep === steps.length - 1"
        @click="handleSubmit"
        :disabled="!validateStep || loading"
      >
        {{ data.submitBtnTitle ? data.submitBtnTitle : 'Submit'}}
      </button>
    </div>
  </div>
</template>

<style lang="scss">
.lead-gen-rec {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Make the container fill the entire viewport height */
}

.step-container {
  padding: 0px 20px; /* Adjust horizontal padding */
}

.lead-gen-rec-header {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Change to space-between to push elements to the edges */
  padding: 0 20px; /* Adjust horizontal padding */
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure the header is above other content */
  background-color: white; /* Add a background color if needed */
  height: 64px; /* Adjust the header height */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a box shadow */
}

.back-arrow {
  cursor: pointer;
}

.logo-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: inherit;
}

.with-arrow .logo-container {
  max-width: calc(100% - 40px); /* Adjust the max width to leave space for the back arrow */
}

.logo-link {
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  max-width: 100%;
  max-height: 100%;
}

.bi-chevron-left::before {
  font-size: x-large;
  color: $btn-navbar-toggle;
}

.content {
  flex-grow: 1; /* Make the content fill the remaining space */
  overflow-y: auto; /* Add vertical scrolling for content if necessary */
}

.lead-gen-rec-footer {
  margin-top: auto; /* Push the footer to the bottom */
  padding: 20px; /* Adjust padding as needed */
  width: 100%; /* Make the footer take up the entire width */
}

.multi-select button,
.age-select button {
  width: 100%;
}

.progress-bar {
  height: 6px; /* Set the height of the progress bar */
  background-color: #f0f0f0; /* Set the background color as grey */
}

.progress {
  height: 100%; /* Set the height to fill the progress bar */
  background-image: linear-gradient(to right, #24ca8b, #1b71b5);
  transition: width 0.3s ease; /* Add transition effect */
}

.lead-gen-rec-footer {
  position: sticky;
  bottom: 0;
  background-color: white; // Ensure the footer background covers content behind it
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000; // Ensure the footer is above other content
}

.next-step-cta {
  margin-bottom: 10px;
  width: 100%;
  max-width: 350px; // Ensure buttons don't exceed max width
  font-weight: 600;
}

.btn-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @include media-breakpoint-up(sm) {
    max-width: 420px;
    margin: 0 auto;
  }

  &.btn-group {
    margin: 0px auto;
    gap: 20px;

    .btn-quiz {
      min-width: 135px;
      width: auto;
      justify-content: center;
      display: flex;
      align-items: center;
      padding: 0px 45px 0px 10px;
      border-radius: 4px;
      border: solid 1px #d9d9d9;
      background-color: white;
      cursor: pointer;
      position: relative;
      color: #737373;
      line-height: 45px;

      &.active {
        border-color: $btn-navbar-toggle;
        color: $btn-navbar-toggle;
        font-weight: 600;
      }

      .bi-check {
        font-size: 25px;
        line-height: 25px;
      }

      .space {
        width: 25px;
      }
    }
  }
}
@media (hover: none) {
  .btn.btn-primary:hover {
    background-color: #1F1F1F; /* Reset hover style */
  }
}
@media (pointer: coarse) {
  .btn.btn-secondary:hover {
    background-color: initial;
    border-color: #d9d9d9;
    color: initial;
    box-shadow: none;
    outline: none;
  }
}
</style>
