<script setup>
definePageMeta({
  middleware:  "page"
});
const route = useRoute()
const {setPageHead} = useGlobalFeaturesData();
let customHeroHeader,sections,overrideHeroHeader,displayHeroHeader,displayJumpToTop,showBrochureRibbon,brochureRibbonTags,showChatService,showPromoBanner = null;
const data = route.pageData;
displayJumpToTop = data.value.jumpToTop;
let preview = route.query.preview
if (!preview && data.value.publishedAt === null) {
  throw createError({ statusCode: 404, statusMessage: "This page doesn't exist ever" })
}

// Set meta tags in head for SEO
if(data.value && data.value.seo) {
  setPageHead(data.value.seo, route.path);
}
if (route.pageType !== 'raw') {

  customHeroHeader = (data.value && !!data.value.heroHeader)? data.value.heroHeader : null;
  // Sections dynamic builder variable
  sections = data.value && data.value.blocks ? data.value.blocks : []
  overrideHeroHeader = customHeroHeader !== null;
  displayHeroHeader = data.value.displayHeroHeader;
  // Show/hide Ribbon Form
  showBrochureRibbon = data.value ? data.value.brochureRibbon : null;
  brochureRibbonTags = data.value ? data.value.brochureRibbonTags : [];
  showChatService = data.value.showChatService;
  showPromoBanner = data.value.showPromoBanner;
}
</script>
<template>
  <LeadGenRecBase :data="route.pageData.value" v-if="route.pageType == 'rec'"/>
  <LayoutHeroHeader v-if="!overrideHeroHeader && displayHeroHeader" :promoBanner="showPromoBanner" />
  <LayoutHeroHeader :heroHeader="customHeroHeader" v-if="overrideHeroHeader && displayHeroHeader" :promoBanner="showPromoBanner" />
  <LayoutDynamicComponents v-if="sections" :sections="sections"/>
  <LayoutRibbonForm v-if="showBrochureRibbon" :ribbonTags="brochureRibbonTags" />
  <LayoutBackToTop v-if="displayJumpToTop"/>
  <GenericContent :data="{content: data.content}" v-if="route.pageType == 'raw'"/>
  <ChatService v-if="showChatService"/>
</template>